var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.studyEnvironmentsFilter),function(study_environment){return [(_vm.StudyEnvironmentTimeAllocation(study_environment.id).length > 0)?_c('div',{key:study_environment.id},[_c('div',{staticClass:"header"},[_c('strong',[_vm._v(_vm._s(study_environment.name)+":")])]),_vm._l((_vm.StudyEnvironmentTimeAllocation(
          study_environment.id
        )),function(time_allocation){return [_c('div',{key:study_environment.id + '-' + time_allocation.id},[(_vm.isOlMatter)?[(
                _vm.MatEvaStuEnvTimAll.filter(
                  (x) =>
                    x.study_environment == study_environment.id &&
                    x.matter_evaluation == _vm.matter_evaluation_id &&
                    (x.time_allocation == time_allocation.id || x.hours > 0)
                ).length > 0
              )?[_vm._l((_vm.MatEvaStuEnvTimAll.filter(
                  (x) =>
                    x.study_environment == study_environment.id &&
                    x.matter_evaluation == _vm.matter_evaluation_id &&
                    (x.time_allocation == time_allocation.id || x.hours > 0)
                )),function(mat_eva_stu_env_tim_all){return [_c('MatEvaStuEnvTimAllInput',{key:study_environment.id +
                    '-' +
                    time_allocation.id +
                    '-' +
                    mat_eva_stu_env_tim_all.id,staticClass:"ml-5",attrs:{"matter_id":_vm.matter_id,"matter_evaluation_id":_vm.matter_evaluation_id,"MatEvaStuEnvTimAll":mat_eva_stu_env_tim_all,"MatEvaStuEnvTimAllList":_vm.MatEvaStuEnvTimAll,"tmpMatEvaStuEnvTimAll":_vm.tmpMatEvaStuEnvTimAll,"StudyEnvironmentIds":_vm.StudyEnvironmentIds,"isPedagogical":_vm.isPedagogical,"time_allocation_id":time_allocation.id,"study_environment_id":study_environment.id,"isOlMatter":_vm.isOlMatter,"show_button_delete":true},on:{"created":_vm.createdMatStuEnvTimAll,"updated":_vm.UpdateMatStuEnvTimAll,"deleted":_vm.deleteMatStuEnvTimAll}})]})]:_c('MatEvaStuEnvTimAllInput',{staticClass:"ml-5",attrs:{"matter_id":_vm.matter_id,"matter_evaluation_id":_vm.matter_evaluation_id,"MatEvaStuEnvTimAllList":_vm.MatEvaStuEnvTimAll,"tmpMatEvaStuEnvTimAll":_vm.tmpMatEvaStuEnvTimAll,"StudyEnvironmentIds":_vm.StudyEnvironmentIds,"isPedagogical":_vm.isPedagogical,"time_allocation_id":time_allocation.id,"study_environment_id":study_environment.id,"isOlMatter":_vm.isOlMatter},on:{"created":_vm.createdMatStuEnvTimAll,"updated":_vm.UpdateMatStuEnvTimAll}})]:[(
                _vm.MatEvaStuEnvTimAll.filter(
                  (x) =>
                    x.study_environment == study_environment.id &&
                    x.egress_profile_matter_evaluation ==
                      _vm.matter_evaluation_id &&
                    (x.time_allocation == time_allocation.id || x.hours > 0)
                ).length > 0
              )?[_vm._l((_vm.MatEvaStuEnvTimAll.filter(
                  (x) =>
                    x.study_environment == study_environment.id &&
                    x.egress_profile_matter_evaluation ==
                      _vm.matter_evaluation_id &&
                    (x.time_allocation == time_allocation.id || x.hours > 0)
                )),function(mat_eva_stu_env_tim_all){return [_c('MatEvaStuEnvTimAllInput',{key:study_environment.id +
                    '-' +
                    time_allocation.id +
                    '-' +
                    mat_eva_stu_env_tim_all.id,staticClass:"ml-5",attrs:{"matter_id":_vm.matter_id,"matter_evaluation_id":_vm.matter_evaluation_id,"MatEvaStuEnvTimAll":mat_eva_stu_env_tim_all,"MatEvaStuEnvTimAllList":_vm.MatEvaStuEnvTimAll,"tmpMatEvaStuEnvTimAll":_vm.tmpMatEvaStuEnvTimAll,"StudyEnvironmentIds":_vm.StudyEnvironmentIds,"isPedagogical":_vm.isPedagogical,"time_allocation_id":time_allocation.id,"study_environment_id":study_environment.id,"isOlMatter":_vm.isOlMatter,"show_button_delete":true},on:{"created":_vm.createdMatStuEnvTimAll,"updated":_vm.UpdateMatStuEnvTimAll,"deleted":_vm.deleteMatStuEnvTimAll}})]})]:_c('MatEvaStuEnvTimAllInput',{staticClass:"ml-5",attrs:{"matter_id":_vm.matter_id,"matter_evaluation_id":_vm.matter_evaluation_id,"MatEvaStuEnvTimAllList":_vm.MatEvaStuEnvTimAll,"tmpMatEvaStuEnvTimAll":_vm.tmpMatEvaStuEnvTimAll,"StudyEnvironmentIds":_vm.StudyEnvironmentIds,"isPedagogical":_vm.isPedagogical,"time_allocation_id":time_allocation.id,"study_environment_id":study_environment.id,"isOlMatter":_vm.isOlMatter},on:{"created":_vm.createdMatStuEnvTimAll,"updated":_vm.UpdateMatStuEnvTimAll}})]],2)]})],2):_vm._e()]}),(_vm.timeAllocationWithNoStudyEnvironment.length > 0)?[(_vm.studyEnvironmentsFilter.length > 0 && _vm.containHours.length > 0)?_c('div',{staticClass:"header"},[_c('strong',[_vm._v("Sin "+_vm._s(_vm.$getVisibleNames( "mesh.studyenvironment", false, "Ambiente de Aprendizaje" ))+":")])]):_vm._e(),_vm._l((_vm.timeAllocationWithNoStudyEnvironment),function(time_allocation){return [_c('div',{key:'time_allocation:' + time_allocation.id},[(_vm.isOlMatter)?[(
              _vm.MatEvaStuEnvTimAll.filter(
                (x) =>
                  x.study_environment == null &&
                  x.time_allocation == time_allocation.id &&
                  x.matter_evaluation == _vm.matter_evaluation_id
              ).length > 0
            )?[_vm._l((_vm.MatEvaStuEnvTimAll.filter(
                (x) =>
                  x.study_environment == null &&
                  x.time_allocation == time_allocation.id &&
                  x.matter_evaluation == _vm.matter_evaluation_id
              )),function(mat_eva_stu_env_tim_all){return [_c('MatEvaStuEnvTimAllInput',{key:time_allocation.id + '-' + mat_eva_stu_env_tim_all.id,class:{ 'ml-5': _vm.studyEnvironmentsFilter.length > 0 },attrs:{"matter_id":_vm.matter_id,"matter_evaluation_id":_vm.matter_evaluation_id,"MatEvaStuEnvTimAll":mat_eva_stu_env_tim_all,"MatEvaStuEnvTimAllList":_vm.MatEvaStuEnvTimAll,"tmpMatEvaStuEnvTimAll":_vm.tmpMatEvaStuEnvTimAll,"StudyEnvironmentIds":_vm.StudyEnvironmentIds,"isPedagogical":_vm.isPedagogical,"time_allocation_id":time_allocation.id,"study_environment_id":null,"isOlMatter":_vm.isOlMatter,"show_button_delete":true},on:{"created":_vm.createdMatStuEnvTimAll,"updated":_vm.UpdateMatStuEnvTimAll,"deleted":_vm.deleteMatStuEnvTimAll}})]})]:[_c('MatEvaStuEnvTimAllInput',{class:{ 'ml-5': _vm.studyEnvironmentsFilter.length > 0 },attrs:{"matter_id":_vm.matter_id,"matter_evaluation_id":_vm.matter_evaluation_id,"MatEvaStuEnvTimAllList":_vm.MatEvaStuEnvTimAll,"tmpMatEvaStuEnvTimAll":_vm.tmpMatEvaStuEnvTimAll,"StudyEnvironmentIds":_vm.StudyEnvironmentIds,"isPedagogical":_vm.isPedagogical,"time_allocation_id":time_allocation.id,"study_environment_id":null,"isOlMatter":_vm.isOlMatter},on:{"created":_vm.createdMatStuEnvTimAll,"updated":_vm.UpdateMatStuEnvTimAll}})]]:[(
              _vm.MatEvaStuEnvTimAll.filter(
                (x) =>
                  x.study_environment == null &&
                  x.time_allocation == time_allocation.id &&
                  x.egress_profile_matter_evaluation == _vm.matter_evaluation_id
              ).length > 0
            )?[_vm._l((_vm.MatEvaStuEnvTimAll.filter(
                (x) =>
                  x.study_environment == null &&
                  x.time_allocation == time_allocation.id &&
                  x.egress_profile_matter_evaluation == _vm.matter_evaluation_id
              )),function(mat_eva_stu_env_tim_all){return [_c('MatEvaStuEnvTimAllInput',{key:time_allocation.id + '-' + mat_eva_stu_env_tim_all.id,attrs:{"matter_id":_vm.matter_id,"matter_evaluation_id":_vm.matter_evaluation_id,"MatEvaStuEnvTimAll":mat_eva_stu_env_tim_all,"MatEvaStuEnvTimAllList":_vm.MatEvaStuEnvTimAll,"tmpMatEvaStuEnvTimAll":_vm.tmpMatEvaStuEnvTimAll,"StudyEnvironmentIds":_vm.StudyEnvironmentIds,"isPedagogical":_vm.isPedagogical,"time_allocation_id":time_allocation.id,"study_environment_id":null,"show_button_delete":true,"isOlMatter":_vm.isOlMatter},on:{"created":_vm.createdMatStuEnvTimAll,"updated":_vm.UpdateMatStuEnvTimAll,"deleted":_vm.deleteMatStuEnvTimAll}})]})]:[_c('MatEvaStuEnvTimAllInput',{attrs:{"matter_id":_vm.matter_id,"matter_evaluation_id":_vm.matter_evaluation_id,"MatEvaStuEnvTimAll":_vm.MatEvaStuEnvTimAll.find(
                  (x) =>
                    x.study_environment == null &&
                    x.time_allocation == time_allocation.id &&
                    x.egress_profile_matter_evaluation == _vm.matter_evaluation_id
                ),"MatEvaStuEnvTimAllList":_vm.MatEvaStuEnvTimAll,"tmpMatEvaStuEnvTimAll":_vm.tmpMatEvaStuEnvTimAll,"StudyEnvironmentIds":_vm.StudyEnvironmentIds,"isPedagogical":_vm.isPedagogical,"time_allocation_id":time_allocation.id,"study_environment_id":null,"isOlMatter":_vm.isOlMatter},on:{"created":_vm.createdMatStuEnvTimAll,"updated":_vm.UpdateMatStuEnvTimAll}})]]],2)]})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }